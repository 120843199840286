<template>
    <div>
        <v-card class="staff-activity-action">     
            <v-card-text class="pt-0 pb-0" v-if="!action.isUploadPhoto">
                <div class="row">
                    <div class="col-9 mt-auto mb-auto pt-5 pb-5">
                        <span :class="actionNameCss">{{action.name}}</span>
                    </div>
                    <div class="col-3 staff-activity-action-button">
                        <img v-if="isActive && !isPerformAction" :src="require('/src/assets/img/qrscan_button.png')" @click="scanQR">
                        <img v-if="isActive && isPerformAction" :src="require('/src/assets/img/perform_action.png')" @click="performTask">
                        <i v-if="isCompleted" class="fa fa-check-circle check-mark-completed staff-activity-action-icon-completed"></i>
                    </div>
                </div>    
            </v-card-text>
            <v-card-text v-else>
                <div class="row">
                    <div class="col-12 mt-auto mb-auto pt-5">
                        <span :class="actionNameCss">{{action.name}}</span>
                        <v-progress-circular v-if="isUploading" indeterminate color="blue-grey" :size="25" class="action-photo-loader"></v-progress-circular>
                    </div>
                    <div class="image-upload-container">
                        <div class="image-upload pl-1 pr-1">
                            <label for="file-input">
                                <img :class="cameraCss" :src="require('/src/assets/img/upload_photo.png')">
                            </label>
                            <input id="file-input" type="file" accept="image/*" capture  @change=setPhotoDetail :disabled="activity.status=='Completed'">
                        </div>  
                        <div class="pl-1 pr-1" v-for="(photo, index) in photos" :key="photo.id">
                            <v-badge top overlap right class="check-mark" icon="mdi-close-circle" offset-x="17" offset-y="13">
                                <template v-slot:badge>
                                    <span><i :class="removeImageCss" aria-hidden="true" @click="removePhoto(index)"></i></span>
                                </template>
                                <div>
                                    <img class="activity-action-photo" :src='photoPath + photo.fileName' @click="viewPhoto(index)">
                                </div>
                            </v-badge>
                        </div>
                    </div>
                </div>              
            </v-card-text>
        </v-card>
        <ScanQRCode v-if="showQRScan" @decodeQR="decodeQR" @cancelScan="cancelScan"></ScanQRCode>
        <ViewImage v-if="viewImage" :photos="newPhotos" :selectedImageIndex="selectedImageIndex" @closeViewImage="closeViewImage"></ViewImage>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, uploadPath, uploadTypeActivity} from "/src/constants/config";
import moment from 'moment';
import ScanQRCode from '/src/views/staff/ScanQRCode';
import ViewImage from '/src/views/staff/ViewImage';

export default {
    props: ['action', 'activity', 'photos'],
    mixins: [currentUserMixin],
    components: { ScanQRCode, ViewImage },

    data() {
		return {
            activityIcon: "",   
            showQRScan: false,   
            newPhotos: [],
            uploadingPhoto: "",
            viewImage: false,
            selectedImageIndex: 0,
            isUploading: false,
		}
    },

    mounted() {	
        this.photos.forEach(element => {
            this.newPhotos.push(element.fileName);
        });    
    }, 

    methods: {
        scanQR(){
            this.showQRScan = true;
        },

        decodeQR(decodedString){
            let params = {
                activityActionId: this.action.id,
                subjectId: decodedString
            }

            let url = openRoadApiUrl + "/api/services/" + version + "/activityaction/perform";
            axios.post(url, params, this.$data.apiHeaders )
            .then(response => this.onScanQRSuccess(response))
            .catch(error => this.onError(error));
        },

        onScanQRSuccess(){
            this.showQRScan = false;
            this.$emit("actionCompleted", this.action);
        }, 

        cancelScan(){
            this.showQRScan = false;
        },

        performTask(){
            let params = {
            activityActionId: this.action.id,
            subjectId: "Done"
            }

            let url = openRoadApiUrl + "/api/services/" + version + "/activityaction/perform";
            axios.post(url, params, this.$data.apiHeaders )
            .then(response => this.onPerformSuccess(response))
            .catch(error => this.onError(error));         
        },

        onPerformSuccess(){
            this.$emit("actionCompleted", this.action);
        },

        uploadPhoto(){    
            this.isUploading = true;              
            let params = {
                uploadType: "ACTIVITY",
                fileName: this.uploadingPhoto.fileName,
                fileFormat: this.uploadingPhoto.fileFormat,
                fileData: this.uploadingPhoto.fileData
            };

            let url = openRoadApiUrl + "/api/services/" + version + "/upload";
                axios.post(url, params, this.$data.apiHeaders )
                .then(response => this.onUploadSuccess(response))
                .catch(error => this.onError(error)); 
        },

        onUploadSuccess(response){
            this.newPhotos.push(response.data.result);
            this.submitPhotos();            
        },

        submitPhotos(){
            let params = {
                activityActionId: this.action.id,
                photos: this.newPhotos,
            };

            let url = openRoadApiUrl + "/api/services/" + version + "/activityaction/submitphoto";
                axios.post(url, params, this.$data.apiHeaders )
                .then(response => this.onSuccess(response))
                .catch(error => this.onError(error)); 
        },

        setPhotoDetail(e){
            let picFullName = e.target.files[0].name;
            let picName = picFullName.split('/').pop().split('\\').pop().replace(/[.][^.]+$/, "");
            let picFormat = picFullName.substring(picName.length + 1, picFullName.length);

            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                let previewImage = e.target.result;
                let picData = previewImage.substring(previewImage.indexOf(",") + 1);

                let obj = {};
                obj["uploadType"] = "ACTIVITY",
                obj["fileName"] = picName,
                obj["fileFormat"] = picFormat,
                obj["fileData"] = picData,

                this.uploadingPhoto = obj;
            }
        },

        viewPhoto(index){
            this.selectedImageIndex = index;
            this.viewImage = true;
        },

        closeViewImage(){
            this.viewImage = false;
        },

        removePhoto(index){
            this.isUploading = true; 
            this.newPhotos.splice(index, 1);
            this.submitPhotos(); 
        },

        onSuccess(response) {
            let message = "Success";
            this.$notify("success", message, "", {
                duration: 6000,
                permanent: false
            });
            this.$emit("reloadData");
            this.isUploading = false;
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                            ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                            : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        },
    },

    computed: {
        isCompleted(){
            return this.action.status && !this.action.isUploadPhoto;
        },
        isActive(){
            return this.action.isActive && !this.action.isUploadPhoto;
        },
        actionNameCss(){
            return this.action.isActive || this.action.status ? "staff-activity-action-text-active" : "staff-activity-action-text";
        }, 
        photoPath(){
            return uploadPath + "/" + uploadTypeActivity + "/";
        },
        cameraCss(){
            return this.activity.status == "Completed" ? "activity-action-photo-disabled" : "activity-action-photo";
        },
        isPerformAction(){
            return this.action.actionType == "PerformAction" ? true : false;
        },
        removeImageCss(){
            return this.activity.status == "Completed" ? "" : "fa fa-times-circle activity-action-photo-remove";
        }
    },

    watch: {
        uploadingPhoto(){
            this.uploadPhoto();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>