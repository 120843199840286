<template>
    <div class="page-height background-detail">
        <v-container class="bill-background profile-position container-header">
            <div class="row">
                <div class="col-3 col-md-2 col-sm-2">
                    <v-avatar class="profile-img">
                    <img
                        src='../assets/img/profile_pic.png'
                        alt="John"
                    >
                    </v-avatar>
                </div>
                <div class="col-9 align center">
                    <div class="row">
                        <div class="col-12 pb-0">
                            <span class="profile-title">My Profile</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pt-0">
                            <span class="profile-name">{{this.userInfo.name | formatData}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </v-container>
         <v-container class="page-content-offset profile-position container-body">
            <div class="row">
                <div class="col-12">
                    <span class="bill-all-invoices"><b>Customer Profile</b></span>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12 profile-data-title-col">
                            <span class="profile-data-title">Customer Since</span>
                        </div>
                        <div class="col-12 profile-data-data-col">
                            <span class="profile-data-data">{{this.userInfo.creationTime | formatDate}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 profile-data-title-col">
                            <span class="profile-data-title">Company Name</span>
                        </div>
                        <div class="col-12 profile-data-data-col">
                            <span class="profile-data-data">{{this.userInfo.companyName | formatData}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 profile-data-title-col">
                            <span class="profile-data-title">Company Registered Number</span>
                        </div>
                    <div class="col-12 profile-data-data-col">
                            <span class="profile-data-data">{{this.userInfo.companyRegistrationNo | formatData}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="row">
                        <div class="col-12 profile-data-title-col">
                            <span class="profile-data-title">Address</span>
                        </div>
                        <div class="col-12 profile-data-data-col">
                            <span class="profile-data-data">{{this.userInfo.fullAddress | formatData}}</span>
                        </div>
                    </div>
                    <div class="row">
                    <div class="col-12 profile-data-title-col">
                            <span class="profile-data-title">Mobile</span>
                        </div>
                        <div class="col-12 profile-data-data-col">
                            <span class="profile-data-data">{{this.userInfo.phoneNumber | formatData}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 profile-data-title-col">
                            <span class="profile-data-title">Email</span>
                        </div>
                        <div class="col-12 profile-data-data-col">
                            <span class="profile-data-data">{{this.userInfo.emailAddress | formatData}}</span>
                        </div>
                    </div>
                     <div class="row">
                        <div class="col-12 profile-data-title-col">
                            <span class="profile-data-title">Emergency Contact</span>
                        </div>
                        <div class="col-12 profile-data-data-col">
                            <span class="profile-data-data">
                                <a :href="'tel:+6' + this.userInfo.emergencyContactPhoneNumber" class="emergency-contact">
                                    <span>{{emergencyContact | formatData}}</span>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="row mt-5">
                <div class="col-6 col-md-3 col-sm-3">
                    <router-link to="/changepassword" class="link-deco">
                        <v-btn
                        elevation="2"
                        class="profile-button"
                        ><b>Change Password</b>
                        </v-btn>
                    </router-link>
                </div>
                <div class="col-6 col-md-3 col-sm-3">
                        <v-btn
                        elevation="2"
                        class="profile-button"
                        @click="logout"
                        ><b>Logout</b>
                        </v-btn>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { openRoadApiUrl, version } from "/src/constants/config";
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import moment from 'moment';

export default {
  mixins: [currentUserMixin],
  data() {
    return {
        userInfo: {},
    }
  },

  components: {
  },
  
    mounted() {		
        this.userInfo = this.currentUser.details;
        if(!this.userInfo){
            this.getCurrentUserDetail();            
        }
    }, 

    methods: {
        ...mapActions(["signOut"]),
        logout() {
            this.signOut().then(() => {
              this.$router.push("/login");
            });
        },

        getUserDetail(){
            let url = openRoadApiUrl + "/api/services/" + version + "/user/me";
            axios.get(url, this.$data.apiHeaders)
                .then(response => this.setUserDetail(response))
                .catch(error => this.onError(error));
        },

        setUserDetail(response){    
            this.userInfo = response.data; 
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        },
    },

    computed: {
        emergencyContact(){
            let value = "";
            if(this.userInfo.emergencyContactPhoneNumber && this.userInfo.emergencyContactName){
                value = this.userInfo.emergencyContactPhoneNumber + ' (' + this.userInfo.emergencyContactName + ')';
            }
            else if(this.userInfo.emergencyContactPhoneNumber){
                value = this.userInfo.emergencyContactPhoneNumber
            }
            else if(this.userInfo.emergencyContactName){
                value = this.userInfo.emergencyContactName
            }

            return value;
        }
    },

    
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
