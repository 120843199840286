<template>
  <v-card
    class="mx-auto filter-container"
    max-width="300"
    dark
    rounded
  >
    <v-list dense>
      <v-list-item-group
        v-model="selectedFilter"
        color="primary"
      >
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
         @click="filterActivity(item)">
          <v-list-item-icon class="activity-filter-icon">
            <img class="activity-filter-img" :src="item.src">
          </v-list-item-icon>
          <v-list-item-content>
              <span class="activity-filter-text">{{item.text}}</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { activityCategories} from "/src/constants/config";
import _ from "lodash";

  export default {
    props:['currentSelectedFilter'],

    components: {
    },

    data() {
		return {
            selectedFilter: "",
            items: activityCategories,
		}
    },

    mounted(){
      let selectedItem = _.findIndex(this.items, function(o){return o.activityCategoryId == this.currentSelectedFilter.activityCategoryId}.bind(this));
      this.selectedFilter = selectedItem > 0 ? selectedItem : 0;
    },

    methods: {	
        filterActivity(item){
            this.$emit("filterActivity", item);
            this.selectedFilter = item;
        }		
	},


    watch: {
      currentSelectedFilter(val){
        let selectedItem = _.findIndex(this.items, function(o){return o.activityCategoryId == this.currentSelectedFilter.activityCategoryId}.bind(this));
        this.selectedFilter = this.selectedItem ? this.selectedItem : 0;
      }
    },

    computed: {
  },
  }
</script>