<template>
    <div class="page-height background-detail">
        <v-container class="staff-page-container page-content-offset page-height" v-if="!showQRScan && !showBookingDetail">
            <div class="row">
                <div class="col-12">
                    <span></span>
                </div>
            </div>
            <div class="button-checkinout-container">
                <div class="row">
                    <div class="col-12">
                        <v-btn outlined :class="checkInButtonCss" @click="checkIn">
                            <div class="row">
                                <div class="col-2 pl-0">
                                    <img :src="checkInImgSrc">
                                </div>
                                <div class="col-8">
                                    Check-in Car
                                </div>
                            </div>
                        </v-btn>
                    </div>
                </div>
                <div class="row button-checkinout-position">
                    <div class="col-12">
                        <v-btn outlined :class="checkOutButtonCss" @click="checkOut">
                            <div class="row">
                                <div class="col-2">
                                </div>
                                <div class="col-8">
                                    Check-out Car
                                </div>
                                <div class="col-2 pr-0">
                                    <img :src="checkOutImgSrc">
                                </div>
                            </div>
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-container>

        <ScanQRCode v-if="showQRScan" :actionText="actionText" @decodeQR="decodeQR" @cancelScan="cancelScan"></ScanQRCode>
        <BookingDetail v-if="showBookingDetail" :bookingDetail="bookingDetail" :isCheckIn="isCheckIn"
                        @checkInOut="checkInOut" @cancelCheckInOut="cancelCheckInOut"></BookingDetail>

    </div>
</template>

<script>
import TasksPage from './TasksPage.vue';
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import {openRoadApiUrl, version} from "/src/constants/config";
import ScanQRCode from '/src/views/staff/ScanQRCode';
import BookingDetail from '/src/views/staff/BookingDetail';

export default {
    props: [],
    mixins: [currentUserMixin],

    data() {
		return {
            isCheckIn: true,
            isCheckOut: false,
            showQRScan: false,
            showBookingDetail: false,
            bookingDetail: "",
            actionText: " to proceed check-in",
		}
    },

    components: { ScanQRCode, BookingDetail },
  
    mounted() {	  
    }, 

    methods: {
        checkIn(){
            this.actionText = " to proceed check-in",
            this.isCheckIn = true;
            this.isCheckOut = false;
            this.showQRScan = true; 
        },

        checkOut(){
            this.actionText = " to proceed check-out",
            this.isCheckIn = false;
            this.isCheckOut = true;
            this.showQRScan = true;
        },

        decodeQR(decodedString){
            let url = openRoadApiUrl + "/api/services/" + version + "/booking/staff/view?CarId=" + decodedString  + "&BookingType=" + this.bookingType;
            axios.get(url, this.$data.apiHeaders)
            .then(response => this.onScanQRSuccess(response))
            .catch(error => this.onError(error));
        },

        onScanQRSuccess(response){
            this.showQRScan = false;
            this.bookingDetail = response.data.result;
            this.showBookingDetail = true;
        }, 

        cancelScan(){
            this.showQRScan = false;
        },
        
        checkInOut(){
            this.showBookingDetail = false;
        },

        cancelCheckInOut(){
            this.showBookingDetail = false;
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                            ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                            : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        },

    },

    computed: {
        checkInButtonCss(){
            return this.isCheckIn ? "button-checkinout button-checkinout-active" : "button-checkinout";
        },
        checkOutButtonCss(){
            return this.isCheckOut ? "button-checkinout button-checkinout-active" : "button-checkinout";
        }, 
        checkInImgSrc(){
            return this.isCheckIn ? require('/src/assets/img/check-in_active.png') : require('/src/assets/img/check-in.png'); 
        },
        checkOutImgSrc(){
            return this.isCheckOut ? require('/src/assets/img/check-out_active.png') : require('/src/assets/img/check-out.png'); 
        },
        bookingType(){
            return this.isCheckIn ? "Checkin" : "Checkout";
        },
    }, 

    watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>