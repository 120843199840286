<template>
     <div class="background-detail staff-activity-scan-qr-container">
            <div class="pt-4 pr-4 pb-4 pl-4">
                <div class="row">
                    <div class="col-12">
                        <span class="staff-activity-scan-qr-title"> Scan QR Code</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 pt-0">
                        <span class="staff-task-detail-description">Please scan the car QR Code {{this.actionText}}</span>
                    </div>
                </div>
            </div>

            <div class="pt-2">
                <qrcode-stream class="staff-activity-scan-qr-camera" @decode="onDecode"></qrcode-stream>
            </div>

            <div class="pt-5 staff-activity-scan-qr-cancel-button-position">
                <v-btn outlined class="staff-activity-scan-qr-cancel-button" @click="cancel"><span>Cancel</span></v-btn>
            </div>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version} from "/src/constants/config";
import moment from 'moment';

export default {
    props: ['actionText'],
    mixins: [currentUserMixin],

    data() {
		return {     
		}
    },

    components: { },
  
    mounted() {	
    }, 

    methods: {
        onDecode(decodedString) {
            let id = JSON.parse(decodedString).Id;
            this.$emit("decodeQR", id);
        },

        onSuccess(response) {
        },
        
        cancel(){
            this.$emit("cancelScan");
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                            ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                            : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        },
    },

    computed: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>