<template>
  <v-dialog
        v-model="dialog"
        max-width="290"
        dark
      >
        <v-card>
          <v-card-title class="headline">
            {{message}}
          </v-card-title>
  
          <v-card-text>
            {{secondary}}
          </v-card-text>
  
          <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn
              text
              @click="cancel"
            >
              Cancel
            </v-btn> 

             <v-btn
              color="#00B9F2"
              text
              @click="submit"
            >
              Ok
            </v-btn>
            
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
  export default {
    props: ['message', 'secondary', 'confirming'],
    data() {
        return {
            dialog: false,
            isPerformAction: false,
        }
    },

    methods: {
        submit(){
            this.dialog = false;
            this.isPerformAction = true;
            this.$emit("confirm");
        },
        cancel(){
            this.dialog = false;
            this.isPerformAction = true;
            this.$emit("cancel");
        }
    },

    watch: {
        confirming(val) {
            this.dialog = val;
        },

        dialog(val){
          if(!val && !this.isPerformAction){
            this.$emit("cancel");
          }
        }
    },

    computed: {
    }
  }
</script>