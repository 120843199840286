<template>
    <div class="page-height background-detail">
        <v-container class="staff-page-container page-content-offset page-height">
            <v-app-bar app dark class="view-photo-header-bar">
                <div class="pt-4 view-photo-header-bar-content">
                    <v-btn class="view-photo-close" @click="closeView"><i class="fa fa-times" aria-hidden="true"></i></v-btn>
                </div>
            </v-app-bar>
        
            <div class="background-detail view-image-container">
              <div style="opacity:0.8">
                <div class="view-image-inner-container">
                  <agile ref="main" :options="options1" :as-nav-for="asNavFor1" class="main-slide">
                    <div class="slide" v-for="(slide, index) in slides" :key="index">
                        <img :src="slide" class="main-image">
                    </div>
                  </agile>
                  <div class="mx-auto overflow-visible" v-bind:style="thumbnailStyle">
                    <agile ref="thumbnails" :options="options2" :as-nav-for="asNavFor2" class="thumbnails-slide">
                        <div ref="slide--thumbniail" v-for="(slide, index) in slides" :key="index">
                            <img :src="slide" class="thumbnail-image" @click="$refs.main.goTo(index)">
                        </div>
                    </agile>
                  </div>
                </div>
              </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
import { uploadPath, uploadTypeActivity} from "/src/constants/config";
export default {
  props: ['photos', 'selectedImageIndex', 'photosSrc'],
  components: { agile: VueAgile },
  data () {
    return {
      asNavFor1: [],
      asNavFor2: [],
       options1: {
        dots: false,
        fade: false,
        navButtons: false,
        initialSlide: this.selectedImageIndex,
        responsive: [
          {
            breakpoint: 900,
            settings:{
              navButtons: true,
            }
          }
        ]
      },
        
      options2: {
        autoplay: false,
        centerMode: true,
        dots: false,
        navButtons: true,
        slidesToShow: 6,
        infinite: false,
        responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        navButtons: false
                    }
                },              
                {
                    breakpoint: 900,

                }
            ]
      }
    }
  },

  mounted(){
      this.asNavFor1.push(this.$refs.thumbnails)
      this.asNavFor2.push(this.$refs.main)
  },

  methods: {
    closeView(){
        this.$emit("closeViewImage");
    }
  },

  computed:{
    slides(){
      if(this.photosSrc && this.photosSrc.length > 0){
        return this.photosSrc;
      }
      else{   
        let photo = [];
        this.photos.forEach(element => {
          let photoSrc = uploadPath + "/" + uploadTypeActivity + "/" + element;
          photo.push(photoSrc);
        });
        return photo;
      }
    },

    thumbnailStyle() {
      let photoCount = Math.max(this.photos.length, 6);
      let width = (photoCount * 70) + "px";

      return {width: width};
    }
  }
}
</script>

