<template>
    <div class="page-height background-detail">
        <v-container class="staff-page-container page-content-offset page-height">
        <TasksPage :isMyTask="true" :activities="activities" @getActivities="getActivities"></TasksPage>
        </v-container>
    </div>
</template>

<script>
import TasksPage from './TasksPage.vue';
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, activityCategories, uploadPath, uploadTypeCar, statusPending, statusInProgress, statusCompleted} from "/src/constants/config";
import _ from "lodash";
import moment from "moment";

export default {
    props: [],
    mixins: [currentUserMixin],

    data() {
		return {
            activities: [],
            status: "InProgress",
            activitiesPartial: [],
		}
    },

    components: {
        TasksPage,
    },
  
    mounted() {	
        this.getActivities(statusInProgress);     
    }, 

    methods: {
        getActivities(status){
            this.status = status;
            this.page = this.page + 1;
            if(this.currentUser && this.currentUser.details && this.currentUser.details.autoStorageId){     
                axios.get(this.url, this.$data.apiHeaders)
                    .then(response => this.setActivites(response))
                    .catch(error => this.onError(error));
            }    
        },

        setActivites(response) {
            this.activities = response.data.result.items.map(function(item){
                let obj = {};

                obj['userId'] = item.userId;
                obj['userFullName'] = item.userName;
                obj['carId'] = item.carId;
                obj['carRegistrationNo'] = item.carRegistrationNo;
                obj['carParkingLotId'] = item.carParkingLotId ? item.carParkingLotId : "24";
                obj['carCoverPhoto'] = item.carCoverPhoto;
                obj['activityCategoryId'] = item.activityCategoryId;
                obj['activityCategoryName'] = item.activityCategoryName;
                obj['dateAction'] = item.dateAction;
                obj['status'] = item.status;
                obj['id'] = item.id;
                obj['carPathSrc'] = item.carCoverPhoto 
                                    ? uploadPath + "/" + uploadTypeCar + "/thumb/" + item.carCoverPhoto
                                    : "";
                            
                let icon =  _.find(activityCategories, function(o){ return item.activityCategoryId == o.activityCategoryId });
                obj['activityCategoryIcon'] = icon ? icon.src : require('/src/assets/img/Others.png');

                return obj;
            });
        },
        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";
            if(error.response.status == 401) this.logout(message, details);
            else {
              this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
              });
            }
        }
    },

    computed: {
         url(){
            return this.status != statusCompleted 
                    ? openRoadApiUrl + "/api/services/" + version + "/activity/staff/list?Status=" + this.status
                    : openRoadApiUrl + "/api/services/" + version + "/activity/staff/list?Status=" + this.status + 
                       "&DateActionFrom=" + moment().subtract(29, 'days').format("YYYY-MM-DD") + "&DateActionTo=" + moment().format("YYYY-MM-DD");
        }
    }, 

    watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>