<template>
    <div class="page-height background-detail">
        <v-container class="staff-page-container page-content-offset page-height">
            <div class="row">
                <div class="col-12 col-xs-12 col-md-6 car-detail-image-padding">
                    <div class="row">
                        <div class="col-12 car-image" :style="'background-image:url('+ carCoverPhoto + ')'">  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 pb-0">
                            <span class="title-font">{{carBrand}} {{carModel}}</span>
                        </div>
                        <div class="col-4 col-xs-4 col-md-3 col-sm-5">
                            <div class="car-plate car-plate-detail">
                            {{carRegistrationNo}}</div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="!viewImage && !isScanParkingLot && !isCheckInCompleted">
                <div class="row">
                    <div class="col-12">
                        <span class="staff-activity-scan-qr-title">Please fill up {{bookingType}} details</span>
                    </div>
                </div>
                <div class="pl-3 pr-3">
                    <div class="row">
                        <div class="col-12 pb-1">
                            <span class="booking-input-label">Current Mileage</span>
                        </div>
                    </div>
                    <div class="row mt-0 align-center">
                        <div class="col-5">
                            <input type="number" class="booking-input booking-input-mileage" v-model="mileage">
                        </div>
                        <div class="col-7 pl-0">
                            <span class="booking-input-text">KM</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 pb-1">
                            <span class="booking-input-label">Transporter Company Name</span>
                        </div>
                    </div>
                    <div class="row mt-0 align-center">
                        <div class="col-12">
                            <input type="text" class="booking-input booking-input-company" v-model="transporterCompany">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 pb-1">
                            <span class="booking-input-label">Transporter Driver Name</span>
                        </div>
                    </div>
                    <div class="row mt-0 align-center">
                        <div class="col-12">
                            <input type="text" class="booking-input booking-input-company" v-model="transporterDriverName">
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 pb-1">
                            <span class="booking-input-label">Please take photo of the car (Front, Back & Side)</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="image-upload-container">
                            <div class="image-upload pl-1 pr-1">
                                <label for="file-input">
                                    <img class="activity-action-photo" :src="require('/src/assets/img/upload_photo.png')">
                                </label>
                                <input id="file-input" type="file" accept="image/*" capture  @change=setPhotoDetail>
                            </div>  
                            <div class="pl-1 pr-1" v-for="(photo, index) in allPhotos" :key="photo.id">
                                <img class="activity-action-photo" :src='photo.src' @click="viewPhoto(index)">
                            </div>
                        </div>  
                    </div>

                    <div class="row pt-5">
                        <div class="col-12 pb-1">
                            <input type="checkbox" id="confirm" name="confirm" v-model="isConfirm">
                            <label for="confirm" class="booking-input-label pl-2">  I'm confirmed all the details are correct</label>
                        </div>
                    </div>
                </div>
                
                <div class="row pt-5 pl-3">
                    <div class="col-9 booking-button-position">
                        <v-btn :class="buttonCheckInOutCss" :style="buttonCheckInOutStyle" @click="submit">{{buttonText}} 
                            <div class="popup-visible"><v-progress-circular v-if="isProcessing" indeterminate color="blue-grey" :size="25"></v-progress-circular></div>
                        </v-btn>
                        
                        <v-btn outlined class="booking-button" @click="cancel"> Cancel </v-btn>
                    </div>
                </div>  
            </div>      
        
            <CheckInRegistered v-if="isScanParkingLot && !isCheckInCompleted" :bookingDetail="bookingDetail" @scanParkingLotCompleted="scanParkingLotCompleted"></CheckInRegistered>
            <CheckInCompleted v-if="isCheckInCompleted" :bookingType="bookingType"></CheckInCompleted>
        </v-container>

        <ViewImage v-if="viewImage" :photosSrc="photosSrc" :selectedImageIndex="selectedImageIndex" @closeViewImage="closeViewImage"></ViewImage>
    </div>
</template>

<script>
import TasksPage from './TasksPage.vue';
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import {openRoadApiUrl, version, uploadPath, uploadTypeCar, uploadTypeActivity} from "/src/constants/config";
import ViewImage from '/src/views/staff/ViewImage';
import _ from "lodash";
import CheckInRegistered from '/src/views/staff/CheckInRegistered';
import CheckInCompleted from '/src/views/staff/CheckInCompleted';

export default {
    props: ['bookingDetail', 'isCheckIn'],
    mixins: [currentUserMixin],

    data() {
		return {
            carModel: "",
            carBrand: "",
            carCoverPhoto: "",
            carRegistrationNo: "",

            mileage: 0,
            transporterCompany: "",
            transporterDriverName: "",
            parkingLot: "",
            photos: [],
            allPhotos: [],
            isConfirm: false,

            photosSrc: [],
            viewImage: false,
            selectedImageIndex: 0,

            isCheckInCompleted: false,
            isScanParkingLot: false,
            isProcessing: false,
		}
    },

    components: {ViewImage, CheckInRegistered, CheckInCompleted},
  
    mounted() {	
        this.isScanParkingLot =  this.isCheckIn && this.bookingDetail.dateAction ? true : false;
        this.setCarDetail();
    }, 

    methods: {
        setCarDetail(){
            this.carModel = this.bookingDetail.carModel;
            this.carBrand = this.bookingDetail.carBrand;
            this.carRegistrationNo = this.bookingDetail.carRegistrationNo;
            this.carCoverPhoto = this.bookingDetail.carCoverPhoto != "" 
                                ? uploadPath + "/" + uploadTypeCar + "/" + this.bookingDetail.carCoverPhoto
                                : "";
            this.parkingLot = this.bookingDetail.parkingLot;
        },

        cancel(){
            this.$emit("cancelCheckInOut");
        },

        setPhotoDetail(e){
            let picFullName = e.target.files[0].name;
            let picName = picFullName.split('/').pop().split('\\').pop().replace(/[.][^.]+$/, "");
            let picFormat = picFullName.substring(picName.length + 1, picFullName.length);

            const image = e.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = e =>{
                let previewImage = e.target.result;
                let picData = previewImage.substring(previewImage.indexOf(",") + 1);

                let obj = {};
                obj["uploadType"] = "ACTIVITY",
                obj["fileName"] = picName,
                obj["fileFormat"] = picFormat,
                obj["fileData"] = picData,

                obj['src'] = previewImage;
                obj["isUploaded"] = false;

                this.allPhotos.push(obj);
                this.photosSrc.push(previewImage);
            }
        },

        submit(){   
            this.isProcessing = true;
            if(this.allPhotos.length > 0){
                this.allPhotos.forEach(element => {
                let params = {
                    uploadType: element.uploadType,
                    fileName: element.fileName,
                    fileFormat: element.fileFormat,
                    fileData: element.fileData
                }
            
                let url = openRoadApiUrl + "/api/services/" + version + "/upload";
                    axios.post(url, params, this.$data.apiHeaders )
                    .then(response => this.onUploadSuccess(response, element))
                    .catch(error => this.onError(error)); 
                });            
            }

            else{
                this.checkInOut();
            }            
        },

        onUploadSuccess(response, element){
            element.fileName = response.data.result;
            element.isUploaded = true;

            let photoRequiredUpload = _.find(this.allPhotos, function(o){ return !o.isUploaded});
            if(photoRequiredUpload == null){
                this.photos = [];
                this.allPhotos.forEach(function(element){
                let obj = {};
                obj['fileName'] = element.fileName;
                
                this.photos.push(obj);
                }.bind(this));
                this.checkInOut();
            }
        },
        
        viewPhoto(index){
            this.selectedImageIndex = index;
            this.viewImage = true;
        },

        closeViewImage(){
            this.viewImage = false;
        },

        checkInOut(){      
            let params = {
                id: this.bookingDetail.id,
                mileage: this.mileage,
                transporterCompany: this.transporterCompany,
                transporterDriverName: this.transporterDriverName,
                photos: this.photos
            }

            if(this.isCheckIn){
                let url = openRoadApiUrl + "/api/services/" + version + "/booking/staff/checkin";
                axios.post(url, params, this.$data.apiHeaders )
                .then(response => this.onSuccess(response))
                .catch(error => this.onError(error));
            }
            else{
                let url = openRoadApiUrl + "/api/services/" + version + "/booking/staff/checkout";
                axios.post(url, params, this.$data.apiHeaders )
                .then(response => this.onSuccess(response))
                .catch(error => this.onError(error));
            }         
        },

        scanParkingLotCompleted(){
            this.isCheckInCompleted = true;
        },

        onSuccess(response) {
            let message = "Success";
            this.$notify("success", message, "", {
                duration: 6000,
                permanent: false
            });

            if(this.isCheckIn){
                this.isScanParkingLot = true;
            }
            else{
                this.$emit("checkInOut");
            }  
            
            this.isProcessing = false;
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                            ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                            : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
            this.isProcessing = false;
        },
    },

    computed: {
        bookingType(){
            return this.isCheckIn ? "Check-in" : "Check-out";
        },
        buttonText(){
            return this.isCheckIn ? "Check-in Car" : "Check-out Car";
        },
        photoPath(){
            return uploadPath + "/" + uploadTypeActivity + "/";
        },
        buttonCheckInOutCss(){
            return this.isCheckIn ? "booking-button booking-checkin" : "booking-button booking-checkout";
        },
        buttonCheckInOutStyle(){
            return this.isConfirm && !this.isProcessing ? "opacity:1" : "pointer-events: none; opacity:0.5";
        },  
    }, 

    watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>