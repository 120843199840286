<template>
    <div>
        <div class="row">
            <div class="col-12">
                <span class="staff-activity-scan-qr-title">Checked-in registered</span>
            </div>
        </div>
        <div class="pl-3 pr-3">
            <div class="row">
                <div class="col-12 pb-1">
                    <span class="booking-input-label">Please proceed to drive this car to the parking lot :</span>
                </div>
            </div>
            <div class="row mt-0 pb-7 align-center">
                <div class="col-5">
                    <input type="number" class="booking-parking-lot" disabled v-model="parkingLotNo">
                </div>
            </div>

            <v-card class="staff-activity-action"> 
            <div class="row">
                <div class="col-9 mt-auto mb-auto pt-5 pl-5 pb-5">
                    <span class="staff-activity-action-text-active">Scan car park lot QR code</span>
                </div>
                <div class="col-3 staff-activity-action-button">
                    <img :src="require('/src/assets/img/qrscan_button.png')" @click="scanQR">
                </div>
            </div>   
            </v-card> 
        </div>
        <ScanQRCode v-if="showQRScan" @decodeQR="decodeQR" @cancelScan="cancelScan"></ScanQRCode>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import {openRoadApiUrl, version, uploadPath, uploadTypeCar, uploadTypeActivity} from "/src/constants/config";
import _ from "lodash";
import ScanQRCode from '/src/views/staff/ScanQRCode';

export default {
    props: ['bookingDetail'],
    mixins: [currentUserMixin],

    data() {
		return {
            parkingLotNo: "",
            showQRScan: false,
		}
    },

    components: {ScanQRCode},
  
    mounted() {	  
        this.parkingLotNo = this.bookingDetail && this.bookingDetail.carParkingLot ? this.bookingDetail.carParkingLot.parkingLotNo : "";
    }, 

    methods: {
        scanQR(){
            this.showQRScan = true;
        },

        decodeQR(decodedString){
            let params = {
                id: this.bookingDetail.id,
                parkingLotId: decodedString
            }

            let url = openRoadApiUrl + "/api/services/" + version + "/booking/staff/checkin/complete";
            axios.post(url, params, this.$data.apiHeaders )
            .then(response => this.onScanQRSuccess(response))
            .catch(error => this.onError(error));
        },

        onScanQRSuccess(){
            this.showQRScan = false;
            this.$emit('scanParkingLotCompleted');
        }, 

        cancelScan(){
            this.showQRScan = false;
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                            ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                            : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        },
    },

    computed: {
    }, 

    watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>