<template>
    <div>
        <div class="row">
            <div class="col-12">
                <span class="staff-activity-scan-qr-title">{{bookingText}} registered</span>
            </div>
        </div>
        <div class="pl-3 pr-3">
            <div class="row pt-10">
                <div class="col-12 pb-1">
                    <span class="checkin-completed">Car {{bookingText}} Completed</span>
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
import currentUserMixin from '/src/mixin/currentUserMixin.js';

export default {
    props: ['bookingType'],
    mixins: [currentUserMixin],

    data() {
		return {
            
		}
    },

    components: {},
  
    mounted() {	  
    }, 

    methods: {
    },

    computed: {
        bookingText(){
            return this.bookingType == "Check-in" ? "Checked-in" : "Checked-out";
        }
    }, 

    watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>