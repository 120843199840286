<template>
    <div>
        <v-card :class="detailCss" tile @click="acceptTask">                            
            <v-card-text class="pb-0">
                <div class="row">
                    <div class="col-2 pb-0">
                        <v-badge bottom overlap left class="check-mark" icon="mdi-checkbox-marked-circle" offset-x="18" offset-y="18">
                            <template v-slot:badge>
                                   <span><i :class="statusCss" aria-hidden="true"></i></span>
                            </template>
                            <div>
                                <img class="activity-icon" :src="activity.activityCategoryIcon">
                            </div>
                        </v-badge>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-5 pb-0 staff-activity-car-plate">
                                <span class="staff-activity-car-plate-text">{{activity.carRegistrationNo}}</span>
                            </div>
                            <div class="col-2 pb-0">
                                <img :src="require('/src/assets/img/parking_lot.png')">
                            </div>
                            <div class="col-3 pb-0 staff-activity-parking-lot">
                                {{parkingLotNo}}
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12 pt-0">
                                <span class="staff-task-detail-description">{{activity.activityCategoryName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <img class="staff-activity-car-pic" :src="activity.carPathSrc">
                    </div>
                </div>                            
                <div class="row pt-0 pb-0 mt-0 mb-0"> 
                    <v-divider class="divider"></v-divider>
                </div>                     
            </v-card-text>
        </v-card>
        <ConfirmationDialog :message="confirm_message" :secondary="secondary_message" :confirming="isConfirming" @cancel="isConfirming = false" @confirm="confirmAccept"></ConfirmationDialog>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, statusCompleted} from "/src/constants/config";
import moment from 'moment';
import ConfirmationDialog from "/src/components/ConfirmationDialog";

export default {
    props: ['activity', 'isAllTask', 'isMyTask'],
    mixins: [currentUserMixin],

    data() {
		return {
            activityIcon: "",                
            isConfirming: false,
            confirm_message: "Accept selected task?",
            secondary_message: "",
		}
    },

    components: {
        ConfirmationDialog
    },
  
    mounted() {	
    }, 

    methods: {
        acceptTask(){
            if(this.isAllTask & this.activity.status != statusCompleted){
                this.isConfirming = true;
            }
            else if(this.isMyTask){
                let url = "/staff/activity-action?path=myTasks&Activity=" + this.activity.id;
                this.$router.push(url);
            }            
        },

        confirmAccept(){
            this.isConfirming = false;
            let params = {                
                id: this.activity.id,
            };

            let url = openRoadApiUrl + "/api/services/" + version + "/activity/staff/accept";
            axios.post(url, params, this.$data.apiHeaders )
            .then(response => this.onAcceptSuccess(response))
            .catch(error => this.onError(error));
        }, 

        onAcceptSuccess(response) {
            let url = "/staff/activity-action?path=allTasks&Activity=" + this.activity.id;
            this.$router.push(url);
        },

        onSuccess(response) {
            let message = "Success";
            this.$notify("success", message, "Task accepted", {
                duration: 6000,
                permanent: false
            });
            this.$emit("reloadData");
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                            ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                            : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        },
    },

    computed: {
        statusCss(){
            let css = "";
            if(this.activity.status == "Completed"){
                css = "fa fa-check-circle check-mark-completed";
            }           
            
            else if(this.activity.userId && this.activity.status == "InProgress"){
                css = "fa fa-check-circle check-mark-inprogress";
            }
            return css
        }, 

        detailCss(){
            return this.isMyTask || this.isAllTask ?
                    "staff-activity-detail-container":
                    "staff-activity-action-container-header"
        },

        parkingLotNo(){
            return this.activity.carParkingLotId ? 
                    this.activity.carParkingLotId.substring(this.activity.carParkingLotId.lastIndexOf('.') + 1)
                    : "";
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>