<template>
        <v-card color=black tile>                            
            <v-card-text class="pl-0 pr-0">
                <div class="row pt-0">
                    <div class="col-12 col-md-12 activity-button-position">
                        <v-btn outlined :class="todoButtonCss" @click="getAvailableActivities">
                           To do
                        </v-btn>
                        <v-btn outlined :class="completedButtonCss" @click="getCompletedActivities">
                            Completed
                        </v-btn>
                        <v-btn outlined class="activity-button" active-class="activity-button-active"  @click="toggleFilters">
                            Filter
                            <img class="pl-2" :src="require('/src/assets/img/filter.png')">
                        </v-btn>
                    </div>
                </div>
                <div class="pt-6">
                    <div v-for="activity in allActivities" :key="activity.id">
                        <ActivityDetail :activity="activity" @reloadData="reloadData" 
                        :isAllTask="isAllTask" :isMyTask="isMyTask"></ActivityDetail>
                    </div>
                </div>
            </v-card-text>

            <div :class="filterCss">
            <ActivityFilters v-if="showFilter" @filterActivity="filterActivity" :currentSelectedFilter="currentSelectedFilter"></ActivityFilters>
            </div>
        </v-card>
</template>

<script>
import ActivityDetail from './ActivityDetail.vue';
import ActivityFilters from '/src/views/ActivityFilters.vue';
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, activityCategories, uploadPath, uploadTypeCar, statusPending, statusInProgress, statusCompleted} from "/src/constants/config";
import _ from "lodash";

export default {
    props: ['url', 'isAllTask', 'isMyTask', 'activities'],
    mixins: [currentUserMixin],

    data() {
		return {
            allActivities: [],
            showFilter: false,
            selectedFilterCategoryId: "All",       
            currentSelectedFilter: "",
            isCompleted: false,
            isTodo: true,
		}
    },

    components: {
        ActivityDetail,
        ActivityFilters
    },
  
    mounted() {		   
    }, 

    methods: {
        getCompletedActivities(){
            this.isTodo = false;
            this.isCompleted = true;
            this.showFilter = false;
            this.$emit("getActivities", statusCompleted);
        },

        getAvailableActivities(){
            this.isTodo = true;
            this.isCompleted = false;            
            this.showFilter = false;
            if(this.isMyTask){
                this.$emit("getActivities", statusInProgress);
            }
            else if(this.isAllTask){
                this.$emit("getActivities", statusPending);
            }
        },

        toggleFilters(){
            this.showFilter = !this.showFilter;
        },

        filterActivity(item){
            this.currentSelectedFilter = item
            this.selectedFilterCategoryId = item.activityCategoryId;
            this.showFilter = false;

            this.getFilterActivities();           
        },

        getFilterActivities(){
            let activitiesFilterByCategory = this.activities;

            if(this.selectedFilterCategoryId == "Others"){
                activitiesFilterByCategory = _.differenceBy(this.activities, activityCategories, 'activityCategoryId');
            }
            else if(this.selectedFilterCategoryId != "All"){
                activitiesFilterByCategory = _.filter(this.activities, function(o){ return o.activityCategoryId == this.selectedFilterCategoryId }.bind(this));
            } 
            
            this.allActivities = activitiesFilterByCategory;
        },

        reloadData(){
            this.getFilterActivities();
        },

        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        }
    },

    computed: {
        filterCss(){
            return this.showFilter ? "activity-filter-position activity-filter-position-active" : "activity-filter-position";
        },
        todoButtonCss(){
            return this.isTodo ? "activity-button activity-button-active" : "activity-button";
        },
        completedButtonCss(){
            return this.isCompleted ? "activity-button activity-button-active" : "activity-button";
        }
    }, 

    watch: {
        activities(){
            this.getFilterActivities();
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>