<template>
    <div class="page-height background-detail">
        <v-container class="staff-page-container page-content-offset page-height">
            <v-app-bar app dark class="staff-header-bar">
                <div class="pt-4 staff-activity-action-header">
                    <ActivityDetail :activity="activity" v-if="loadDetail"></ActivityDetail>
                </div>
            </v-app-bar>

            <div class="pr-4 pl-4">
                <div class="row pt-4">
                    <div class="col-12">
                        <span class="staff-task-detail-description">{{activity.todo}}</span>
                    </div>
                </div>
                <div class="pt-10">
                    <div v-for="action in activityActions" :key="action.id">
                        <ActionDetail :action="action" :activity="activity" :photos="activity.photos" @actionCompleted="actionCompleted" @reloadData="reloadData"></ActionDetail>
                    </div>
                </div>   

                <div class="row button-position" v-if="showCompleteButton">
                    <v-btn class="task-completed" @click="completeTask">
                        <span class="task-completed-font">Task Completed</span>
                        <img class="pl-3" :src="require('/src/assets/img/like.png')">
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import axios from 'axios';
import currentUserMixin from '/src/mixin/currentUserMixin.js';
import { openRoadApiUrl, version, activityCategories, uploadPath, uploadTypeCar, uploadTypeActivity } from "/src/constants/config";
import _ from "lodash";
import ActivityDetail from './ActivityDetail.vue';
import ActionDetail from './ActionDetail.vue';

export default {
    props: [''],
    mixins: [currentUserMixin],

    data() {
		return {
            activityId: "",    
            activity: {},  
            loadDetail: false,   
            activityActions: [],   
            showCompleteButton: false,
		}
    },

    components: {
        ActivityDetail, ActionDetail
    },
  
    mounted() {	
        this.getURLParams();
        this.getActivity();
    }, 

    methods: {
        getURLParams(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            this.activityId = params.get("Activity");
        },

        getActivity(){   
            if(this.activityId != ""){     
                let url = openRoadApiUrl + "/api/services/" + version + "/activity/staff/view/" + this.activityId;
                axios.get(url, this.$data.apiHeaders)
                    .then(response => this.setActivity(response))
                    .catch(error => this.onError(error));
            }         
        },

        setActivity(response) {
            let obj = {};
            let item = response.data.result;

            obj['carId'] = item.carId;
            obj['carRegistrationNo'] = item.carRegistrationNo;
            obj['carParkingLotId'] = item.carParkingLotId;
            obj['carCoverPhoto'] = item.carCoverPhoto;
            obj['activityCategoryId'] = item.activityCategoryId;
            obj['activityCategoryName'] = item.activityCategoryName;
            obj['todo'] = item.todo;
            obj['dateAction'] = item.dateAction;
            obj['status'] = item.status;
            obj['activityActions'] = item.activityActions;
            obj['photos'] = item.photos;
            obj['lastModificationTime'] = item.lastModificationTime;
            obj['lastModifierUserId'] = item.lastModifierUserId;
            obj['stcreationTimeatus'] = item.creationTime;
            obj['creatorUserId'] = item.creatorUserId;
            obj['id'] = item.id;     
            obj['carPathSrc'] = item.carCoverPhoto 
                                    ? uploadPath + "/" + uploadTypeCar + "/" + item.carCoverPhoto
                                    : "";          
    
            let icon =  _.find(activityCategories, function(o){ return item.activityCategoryId == o.activityCategoryId });
            obj['activityCategoryIcon'] = icon ? icon.src : require('/src/assets/img/Others.png');

            this.activity = obj;
    
            this.processActions();
            this.loadDetail = true;
        },

        processActions(){
            let actions = this.activity.activityActions;
            this.activityActions = _.sortBy(actions, function(o) { return o.step; });

            let currentActionRequirePerform = _.findIndex(this.activityActions, function(o){return !o.status});
            this.activityActions.forEach((element, index) => {                
                element['isActive'] = false;
                element['isUploadPhoto'] = false;
                if(index == currentActionRequirePerform){
                    element["isActive"] = true;
                }
                if(index == this.activityActions.length - 1){ //upload photo
                    element["isActive"] = true;
                    element["isUploadPhoto"] = true;
                }
            });

            if( (currentActionRequirePerform == -1 || currentActionRequirePerform == this.activityActions.length - 1)
                && this.activity.status != "Completed"){ // all action done
                this.showCompleteButton = true;
            }
        },

        completeTask(){
            let params = {
                id: this.activity.id,
            };

            let url = openRoadApiUrl + "/api/services/" + version + "/activity/staff/complete";
                axios.post(url, params, this.$data.apiHeaders )
                .then(response => this.onSuccess(response))
                .catch(error => this.onError(error)); 
        },

        actionCompleted(){
            this.getActivity();
        },

        reloadData(){
            this.getActivity();
        },
        
        onSuccess(response) {
            let message = "Success";
            this.$notify("success", message, "", {
                duration: 6000,
                permanent: false
            });

            this.$router.push("/staff/my-tasks");
        },


        onError(error){
            let message = "Failed";
            let details = error && error.response && error.response.data.error 
                    ? ( error.response.data.error.validationErrors ? error.response.data.error.details : error.response.data.error.message )
                    : "";
            this.$notify("error", message, details, {
                duration: 6000,
                permanent: false
            });
        }
    },

    computed: {
    }, 

    watch: {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>